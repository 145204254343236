import React from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import domainName from "../domainName";
import { logOut } from "../redux/slices/login";
import { useDispatch, useSelector } from "react-redux";
import { switchToAdvisor, switchToUser } from "../redux/slices/switchRole";
import { Container, Row, Col, Button, NavDropdown, Modal, Image } from 'react-bootstrap';
import { COLORS } from '../colorTheme/colorTheme';
import { insertAnonymousUserId } from "../redux/slices/login";
import { useTranslation } from "react-i18next";


const Account = () => {
    const [t] = useTranslation("global");

    const { logOutThirdParty } = UserAuth();

    const { token, UserData, UserId } = useSelector((state) => state.login);

    // const { advisor } = useSelector((state) => state.switchRole);

    // Retrieving 'advisor' state from local storage
    const advisorState = JSON.parse(localStorage.getItem('advisorState'));

    const dispatch = useDispatch();
    const navigate = useNavigate();

    //from api to display info
    const [displayUserName, setDisplayUserName] = useState('')
    const [displayImage, setDisplayImage] = useState('')
    const [displayFollowers, setDisplayFollowers] = useState('')
    const [displayFollowing, setDisplayFollowing] = useState('')

    const [totalCancelled, setTotalCancelled] = useState('')
    const [totalCompleted, setTotalCompleted] = useState('')
    const [totalUpcoming, setTotalUpcoming] = useState('')



    async function switchToUserScreen() {

        // dispatch(switchToUser())

        // Storing 'advisor' state in local storage
        localStorage.setItem('advisorState', JSON.stringify({ advisor: false, user: true }));

        // navigate("/")
        window.location.href = "/";
    }

    async function insertAnonymouseUser() {

        //do insert anonymous hereeee
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/insertAnonymouseUser",
                "POST",
                // JSON.stringify({
                // })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                //console.log('responseJson in /user/insertAnonymouseUser', responseJson);

                //here to do dispatch(AutoLogin .. something)
                if (responseJson.Data != null) {
                    // dispatch(switchToUser())
                    // Storing 'advisor' state in local storage
                    localStorage.setItem('advisorState', JSON.stringify({ advisor: false, user: true }));

                    dispatch(logOut());
                    dispatch(insertAnonymousUserId(responseJson.Data._id))
                } else {
                    alert(responseJson.ReturnDesc);
                }
                // anonymous still remain true

            }))
            .catch((error) => {
                alert(t("error.An error occurred. Please try again later."));
            });
    }

    async function getUserInfoForMeMenu() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/meMenu",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "DisplayScreen": "User"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('/user/meMenu responseJson here', responseJson)
                setDisplayUserName(responseJson.Data.UserName)
                setDisplayImage(responseJson.Data.UserImage)
                setDisplayFollowers(responseJson.Data.TotalFollower)
                setDisplayFollowing(responseJson.Data.TotalFollowing)

                setTotalCancelled(responseJson.UserBooking.TotalCancelled)
                setTotalCompleted(responseJson.UserBooking.TotalCompleted)
                setTotalUpcoming(responseJson.UserBooking.TotalUpcoming)
            }))
            .catch((error) => {
                //console.log('/user/meMenu error here', error)
            });
    }

    async function switchToAdvisorScreen() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userSwitchRoleScreen",
                "POST",
                JSON.stringify({
                    "UserId": UserId
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                //console.log('responseJson in /user/userSwitchRoleScreen', responseJson);
                if (responseJson.Data.Role.Advisor) {
                    // switch screen to advisor
                    // dispatch(switchToAdvisor())

                    // Storing 'advisor' state in local storage
                    localStorage.setItem('advisorState', JSON.stringify({ advisor: true, user: false }));

                    // navigate("/")
                    window.location.href = "/";
                } else {
                    // navigate to "become advisor" screen
                    navigate(`/BecomeAnAdvisor?uid=${UserId}`)
                }
            }))
            .catch((error) => {
                alert(t("error.error here"), error)
            });
    }

    const handleSignOut = async () => {
        try {

            await logOutThirdParty()
            await insertAnonymouseUser() // inside got logout function

            // navigate("/signin");
            window.location.href = "/signin";

        } catch (error) {
            //console.log(error)
        }
    }

    function renderSwitchRole(role, title) {
        return (
            <Row className="justify-content-center align-items-center mt-4">
                <Col className="col-lg-12">
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <span style={{ fontWeight: 'bold', fontSize: 25 }}>{role}</span>
                        </Col>
                    </Row>
                    {renderLabel(title, require('../img/icons/switch.png'))}
                </Col>
            </Row>
        )
    }

    function renderAccountAndSecurity() {
        return (
            <Row className="justify-content-center align-items-center mt-4">
                <Col className="col-lg-12">
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <span style={{ fontWeight: 'bold', fontSize: 25 }}>{t('account.Account Settings')}</span>
                        </Col>
                    </Row>
                    <Link style={{ textDecorationLine: 'none', color: COLORS.darkGray }} to={`/accountAndSecurity`}>
                        {renderLabel(t('account.Account & Security'), require('../img/icons/accountSecurity.png'))}
                    </Link>
                    {/* {renderLabel('Notifications',require('../img/icons/NotificationsIcon.png'))} */}
                    {/* {renderLabel('Support', require("../img/icons/support.png"))} */}
                </Col>
            </Row>
        )
    }

    function renderTopLabel() {
        return (
            <Row className="justify-content-center align-items-center" style={{ marginBottom: '3%' }}>
                <Col lg={12}>
                    <Row>
                        <Col xs={9} md={9} lg={9}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center' }}>
                                <Image
                                    src={displayImage || 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'}
                                    onError={(e) => {
                                        e.target.onerror = null; // Prevent infinite loop in case fallback also fails
                                        e.target.src = 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'; // Fallback image
                                    }}
                                    width={50} height={50} style={{ borderRadius: '50%', marginRight: '1%' }}
                                />

                                <div style={{ marginLeft: '2%', display: 'flex', flexDirection: 'column' }}>
                                    <span style={{ fontSize: 16, fontWeight: 'bold' }}>{displayUserName}</span>
                                    <span style={{ fontSize: 14, fontWeight: 'bolder' }}>{t('account.Followers')} {displayFollowers} | {t('account.Following')} {displayFollowing}</span>
                                </div>
                            </div>
                        </Col>
                        <Col className="text-end" xs={3} md={3} lg={3}>
                            <Link style={{ textDecorationLine: 'none', color: COLORS.gray }} to={`/myProfile?userId=${UserData._id}`}>
                                <div>
                                    {t('account.My Profile')} <Image src={require('../img/icons/arrow.png')} width={10} height={15} />
                                </div>
                            </Link>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }

    function renderBusinessLabel() {
        return (
            <Row className="justify-content-center align-items-center mt-4">
                <Col className="col-lg-12">
                    <Row>
                        <Col xs={12} md={12} lg={12}>
                            <span style={{ fontWeight: 'bold', fontSize: 25 }}>My Business</span>
                        </Col>
                    </Row>
                    <Link style={{ textDecorationLine: 'none', color: COLORS.darkGray }} to={`/myListingsAdvisor`}>
                        {renderLabel(t('account.My Listings'), require('../img/icons/accountSecurity.png'))}
                    </Link>
                    <Link style={{ textDecorationLine: 'none', color: COLORS.darkGray }} to={`/myReview`}>{renderLabel(t('account.My Reviews'), require('../img/icons/myreview.png'))}</Link>
                </Col>
            </Row>
        )
    }

    function renderBookingLabel() {
        return (
            <Row className="justify-content-center">
                <Col className="col-lg-12"  >
                    <Container className="box-container p-3" style={{ borderRadius: 15 }}>
                        <Row>
                            <Col>
                                <Image src={require('../img/icons/mybookings.png')} style={{ marginRight: '1%' }} /> <span style={{ fontWeight: 'bold' }}>{t('account.My Bookings')}</span>
                            </Col>
                        </Row>
                        <Row className="mt-1 mb-1">
                            <Col xs={3} md={2} lg={2} style={{ borderStyle: 'solid', borderColor: COLORS.lightGray1, borderWidth: 0, borderRightWidth: '1px' }}>
                                <Row style={{ textAlign: 'center' }}>
                                    <span style={{ fontWeight: 'bold' }}>{totalUpcoming}</span>
                                </Row>
                                <Row style={{ justifyContent: 'center' }}>
                                    {t('account.Upcoming')}
                                </Row>
                            </Col>
                            <Col xs={3} md={2} lg={2} style={{ borderStyle: 'solid', borderColor: COLORS.lightGray1, borderWidth: 0, borderRightWidth: '1px' }}>
                                <Row style={{ textAlign: 'center' }}>
                                    <span style={{ fontWeight: 'bold' }}>{totalCompleted}</span>
                                </Row>
                                <Row style={{ justifyContent: 'center' }}>
                                    {t('account.Completed')}
                                </Row>
                            </Col>
                            <Col xs={3} md={2} lg={2}>
                                <Row style={{ textAlign: 'center' }}>
                                    <span style={{ fontWeight: 'bold' }}>{totalCancelled}</span>
                                </Row>
                                <Row style={{ justifyContent: 'center' }}>
                                    {t('account.Cancelled')}
                                </Row>
                            </Col>
                            <Col xs={3} md={6} lg={6} className="text-end pe-5">
                                <Button onClick={() => { navigate('/mybookings') }}>
                                    {t('account.View all')}
                                </Button>
                            </Col>
                        </Row>
                    </Container>
                </Col>
            </Row>
        )
    }

    function renderLabel(itemtitle, link) {
        return (
            <Row className="justify-content-center mt-3" style={{ borderRadius: 10 }}>
                <Col className="col-lg-12">
                    <Container className="box-container p-2" style={{ borderRadius: 15 }}>
                        <Row className="mt-1 mb-1">
                            <Col xs={9} md={9} lg={9} style={{ alignSelf: 'center' }}><Image src={link} style={{ marginRight: '1%', width: 30, height: 30 }} />{itemtitle}</Col>
                            <Col xs={3} md={3} lg={3} className="text-end" style={{ alignSelf: 'center' }}><Image src={require('../img/icons/arrow.png')} style={{ marginRight: '1%', width: 10, height: 15 }} /></Col>

                        </Row>
                    </Container>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        getUserInfoForMeMenu()
    }, [])

    return (
        <div>
            {advisorState.advisor == true
                ?
                // <div>
                //     <h1>Account page for Advisor, welcome , {JSON.stringify(UserData)}</h1>
                //     <button onClick={() => {
                //         handleSignOut()
                //         dispatch(logOut())
                //         dispatch(switchToUser())
                //     }}>logout</button>
                //     <button onClick={switchToUserScreen}>switch to user</button>
                //     <br />
                //     <button>Advisor here to see booking</button>
                // </div>
                <div className="greybk-div p-lg-5" style={{ height: '150vh' }}>
                    <Container>
                        {renderTopLabel()}
                        {renderBusinessLabel()}
                        <div style={{ cursor: 'pointer' }} onClick={async () => {
                            //console.log('switch to user')
                            await switchToUserScreen()
                        }}>{renderSwitchRole(t('account.Consumer'), t('account.Switch to consumer'))}</div>
                        {renderAccountAndSecurity()}

                        <Row className="justify-content-center align-items-center" style={{ marginTop: '10%' }}>
                            <Col className="col-lg-12">
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Link style={{ textDecorationLine: 'underline', color: COLORS.orange }} onClick={handleSignOut}>
                                            <h3>{t('account.Log out')}</h3>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>

                :
                // <div>
                //     <h1>Account page for normal user, welcome , {JSON.stringify(UserData)}</h1>
                //     <button onClick={() => {
                //         handleSignOut()
                //         dispatch(logOut())
                //     }}>logout</button>
                //     <button onClick={switchToAdvisorScreen}>switch to advisor</button>
                //     <br />
                //     <button>User here to see requests</button>
                // </div>
                <div className="greybk-div p-3" style={{ height: '150vh' }}>
                    <Container>
                        {renderTopLabel()}
                        {renderBookingLabel()}
                        <Link style={{ textDecorationLine: 'none', color: COLORS.darkGray }} to={`/requestlisting`}>{renderLabel(t('account.My Request'), require('../img/icons/myrequest.png'))}</Link>
                        <Link style={{ textDecorationLine: 'none', color: COLORS.darkGray }} to={`/myReview`}>{renderLabel(t('account.My Reviews'), require('../img/icons/myreview.png'))}</Link>
                        <div style={{ cursor: 'pointer' }} onClick={async () => {
                            //console.log('switch to advisor')
                            await switchToAdvisorScreen()
                        }}>{renderSwitchRole(t('account.Advisor'), t('account.Switch to advisor'))}</div>
                        {renderAccountAndSecurity()}

                        <Row className="justify-content-center align-items-center" style={{ marginTop: '10%' }}>
                            <Col className="col-lg-12">
                                <Row>
                                    <Col xs={12} md={12} lg={12}>
                                        <Link style={{ textDecorationLine: 'underline', color: COLORS.orange }} onClick={handleSignOut}>
                                            <h3>{t('account.Log out')}</h3>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }


        </div>
    )
}

export default Account