import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import domainName from '../domainName'
import { useDispatch, useSelector } from "react-redux";
import { COLORS } from "../colorTheme/colorTheme";

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Form from 'react-bootstrap/Form';
import NavDropdown from 'react-bootstrap/NavDropdown';

import { Container, Row, Col, Image, Button, Modal, Alert, Dropdown, Badge } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { switchToAdvisor, switchToUser } from "../redux/slices/switchRole";
import { insertAnonymousUserId } from "../redux/slices/login";
import './Navigation.css';
import logo from '../img/logo.png';
import search from '../img/search.svg';

import { logOut, setProfileImage } from "../redux/slices/login";
import { SHA256 } from "crypto-js";

import OneSignal from 'react-onesignal';
import { useTranslation } from "react-i18next";
import i18next from "i18next";

<script src="https://cdn.onesignal.com/sdks/OneSignalSDK.js" async></script>

const Navigation = ({ onMessageComeIn }) => {
    // const [apiData, setApiData] = useState('');

    const smsKey = process.env.REACT_APP_SMS_SECRET_KEY;
    const apiKey = process.env.REACT_APP_API_KEY;
    // const { logOut } = UserAuth();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    // const [isIOS, setIsIOS] = useState(false);
    // const [isAndroid, setIsAndroid] = useState(false);

    const userAgent = navigator.userAgent;

    const [isIOS, setIsIOS] = useState(!!userAgent.match(/iPad|iPhone|iPod/));
    const [isAndroid, setIsAndroid] = useState(!!userAgent.match(/Android/));

    // const { advisor, user } = useSelector((state) => state.switchRole);

    // Retrieving 'advisor' state from local storage
    const advisorState = JSON.parse(localStorage.getItem('advisorState'));

    const searchRef = useRef(null);
    // const { token, UserData } = useSelector((state) => state.login);
    const { token, UserData, UserId, locationDisplay, profileImage } = useSelector((state) => state.login);

    // for otp count down
    // const [isRequestingOTP, setIsRequestingOTP] = useState(false);
    // const cooldownDuration = 30; // cooldown period in seconds

    // // const [showVerifyWhatsapp, setShowVerifyWhatsapp] = useState(false)

    // const [frontCountryPhoneNumber, setFrontCountryPhoneNumber] = useState('+60');

    // const [numberInput, setNumberInput] = useState('')

    // const [whatsappNumber, setWhatsappNumber] = useState('')

    // const [showEdit, setShowEdit] = useState(false)

    // const [phoneInputDisabled, setPhoneInputDisabled] = useState(false)

    // const [showOtpInput, setShowOtpInput] = useState(false)

    const [unreadMessageCount, setUnreadMessageCount] = useState(0)
    const [unreadNotificationCount, setUnreadNotificationCount] = useState(0)


    const [displayBecomeAnAdvisor, setDisplayBecomeAnAdvisor] = useState(false)
    const [displayBecomeAUser, setDisplayBecomeUser] = useState(false)
    const separatorStyle = {
        content: '',
        position: 'absolute',
        bottom: 0,
        left: '50%',
        transform: 'translateX(-50%)',
        width: '100%',
        height: '2px', /* Height of the separator line */
        backgroundColor: '#000', /* Color of the separator line */
    };

    //
    const [resendMessage, setResendMessage] = useState(false)
    const [view, setView] = useState('');
    const [isWebsite, setIsWebsite] = useState(window.innerWidth > 768);

    const [t, i18n] = useTranslation("global");

    const OneSignalAppId = process.env.REACT_APP_ONESIGNAL_APPID;


    useEffect(() => {
        OneSignal.init({
            appId: OneSignalAppId,
        });

        OneSignal.Notifications.addEventListener("foregroundWillDisplay", foregroundWillDisplayListener);
    }, []);


    function foregroundWillDisplayListener(event) {
        console.log(`notification will display!!!`);
        console.log(event)
        checkProfileImage()
        onMessageComeIn(event.notification.notificationId);
    }

    useEffect(() => {
        const handleResize = () => {
            const width = window.innerWidth;
            if (width < 768) {
                setView('mobile');
            } else if (width >= 768 && width < 1500) {
                setView('laptop');
            } else if (width >= 1500 && width <= 3000) {
                setView('monitor');
            } else {
                setView('default');
            }
        };
        //For the website be more responsive
        handleResize();
        // For resize
        window.addEventListener('resize', handleResize);
        // For cleaning up the event listener on component unmount
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    async function switchToUserScreen() {
        // dispatch(switchToUser())

        // Storing 'advisor' state in local storage
        localStorage.setItem('advisorState', JSON.stringify({ advisor: false, user: true }));

        // navigate("/")
        window.location.href = "/";
    }
    async function insertAnonymouseUser() {
        //do insert anonymous hereeee
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/insertAnonymouseUser",
                "POST",
                // JSON.stringify({
                // })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {

                //return data here
                // console.log('responseJson in /user/insertAnonymouseUser', responseJson);

                //here to do dispatch(AutoLogin .. something)
                if (responseJson.Data != null) {
                    // dispatch(switchToUser());
                    // Storing 'advisor' state in local storage
                    localStorage.setItem('advisorState', JSON.stringify({ advisor: false, user: true }));

                    dispatch(logOut());
                    dispatch(insertAnonymousUserId(responseJson.Data._id))
                    // navigate('signin', {})
                } else {
                    alert(responseJson.ReturnDesc);
                }
                // anonymous still remain true

            }))
            .catch((error) => {
                alert(t("error.An error occurred. Please try again later."));
            });
    }
    async function checkProfileImage() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/meMenu",
                "POST",
                JSON.stringify({
                    "UserId": UserId,
                    "DisplayScreen": "User"
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                // console.log('/user/meMenu responseJson here', responseJson)
                if (responseJson.Data.UserImage) {
                    dispatch(setProfileImage(responseJson.Data.UserImage));
                }

                if (responseJson.Data.UserRole.Advisor) {
                    setDisplayBecomeAnAdvisor(true)
                }

                if (!responseJson.Data.IsAnonymouseUser) {
                    setDisplayBecomeUser(true)
                }

                // if(responseJson.RegisteradivserCountry){
                //     console.log('go run here?', responseJson.RegisteradivserCountry)
                //     dispatch(setRegisteradivserCountry(responseJson.RegisteradivserCountry))
                // }

                setUnreadMessageCount(responseJson.TotalUnreadMessage)
                setUnreadNotificationCount(responseJson.NotificationUnreadCount)
            }))
            .catch((error) => {
                // console.log('/user/meMenu error here', error)
            });
    }
    // async function updateWhatsappNumberWithAPI() {
    //     // console.log("const updateWhatsappNumberWithAPI = (whatsappNumber) =>", whatsappNumber)
    //     // console.log(JSON.stringify({
    //     //     "UserId": UserData._id,
    //     //     "Name": UserData.Name,
    //     //     "Email": UserData.Email,
    //     //     "WhatappsMobileNo": whatsappNumber,
    //     //     "IsUpdateWhatappsMobileNo": true,
    //     //     "MobileNo": UserData.MobileNo,
    //     //     "Photos": []
    //     // }))

    //     await domainName
    //         .serverAPICall(
    //             domainName.debugAPI + "/user/updateUserAccount",
    //             "POST",
    //             JSON.stringify({
    //                 "UserId": UserData._id,
    //                 "Name": UserData.Name,
    //                 "Email": UserData.Email,
    //                 "WhatappsMobileNo": whatsappNumber,
    //                 "IsUpdateWhatappsMobileNo": true,
    //                 "MobileNo": UserData.MobileNo,
    //                 "Photos": []
    //             })
    //         )
    //         .then(domainName.handleServerAPICallErrors)
    //         .then((responseJson => {
    //             // console.log('/user/updateUserAccount whatsapp number update responseJson here', responseJson)
    //             if (responseJson.ReturnCode == 200) {
    //                 setShowVerifyWhatsapp(false)
    //                 window.location.reload();
    //             } else {
    //                 alert('An error occurred. Please contact administrator and report this issue.')
    //             }
    //         }))
    //         .catch((error) => {
    //             // console.log('/user/updateUserAccount whatsapp number update error here', error)
    //         });
    // }

    // async function sendOTPtoVerifyWhatsapp(phoneNumber) {

    //     const smsid = SHA256(phoneNumber + "Adviser2u" + apiKey + smsKey)

    //     await domainName
    //         .serverAPICall(
    //             domainName.debugAPI + "/user/sendTwilioOtp",
    //             "POST",
    //             JSON.stringify({
    //                 "MobileNo": phoneNumber,
    //                 "SMS_ID": smsid.toString()
    //             })
    //         )
    //         .then(domainName.handleServerAPICallErrors)
    //         .then((responseJson => {
    //             // console.log('/user/sendTwilioOtp responseJson here', responseJson)

    //         }))
    //         .catch((error) => {
    //             // console.log('/user/sendTwilioOtp error here', error)
    //         });

    // }


    // async function getWhatsappNumFromAPI() {
    //     await domainName
    //         .serverAPICall(
    //             domainName.debugAPI + `/adviser_plan/selectAdviserPlanDetailsByUserId?UserId=${UserId}`,
    //             "GET",
    //             // JSON.stringify({
    //             //     "MobileNo": phoneNumber,
    //             // })
    //         )
    //         .then(domainName.handleServerAPICallErrors)
    //         .then((responseJson => {
    //             // console.log('/adviser_plan/selectAdviserPlanDetailsByUserId  here', responseJson.Data.SubscribePlan)
    //             // console.log('/adviser_plan/selectAdviserPlanDetailsByUserId whatsapp here', responseJson.Data.WhatappsMobileNo)

    //             if (responseJson.Data.WhatappsMobileNo == null || responseJson.Data.WhatappsMobileNo == undefined || responseJson.Data.WhatappsMobileNo == "") {

    //                 setShowVerifyWhatsapp(true)

    //             }
    //             // here hardcode for display verify whtasapp modal UI
    //             // else {
    //             //     setShowVerifyWhatsapp(true)
    //             // }

    //         }))
    //         .catch((error) => {
    //             // console.log('/adviser_plan/selectAdviserPlanDetailsByUserId error here', error)
    //         });
    // }
    async function switchToAdvisorScreen() {
        await domainName
            .serverAPICall(
                domainName.debugAPI + "/user/userSwitchRoleScreen",
                "POST",
                JSON.stringify({
                    "UserId": UserId
                })
            )
            .then(domainName.handleServerAPICallErrors)
            .then((responseJson => {
                // console.log('responseJson in /user/userSwitchRoleScreen', responseJson);
                if (responseJson.Data.Role.Advisor) {
                    // switch screen to advisor
                    // dispatch(switchToAdvisor())

                    // Storing 'advisor' state in local storage
                    localStorage.setItem('advisorState', JSON.stringify({ advisor: true, user: false }));

                    // navigate("/")
                    window.location.href = "/";
                } else {
                    // navigate to "become advisor" screen
                    navigate(`/BecomeAnAdvisor?uid=${UserId}`)
                }
            }))
            .catch((error) => {
                alert(t("error.error here"), error)
            });
    }

    // function VerifyWhatsappModal() {
    //     return (
    //         <Modal show={showVerifyWhatsapp} backdrop="static" keyboard={false} fade={false}>
    //             <Modal.Header>
    //                 <Modal.Title>Confirm your whatsapp</Modal.Title>
    //             </Modal.Header>
    //             <Modal.Body>
    //                 <Row style={{ paddingLeft: '2%' }}>Whatsapp No * </Row>
    //                 <Row>
    //                     <Col className="col-3">
    //                         <Dropdown className="mt-2">
    //                             <Dropdown.Toggle className="form-control" variant="light" id="countryDropdown" style={{ backgroundColor: COLORS.white, borderColor: COLORS.lightGray1 }}>
    //                                 {frontCountryPhoneNumber}
    //                             </Dropdown.Toggle>
    //                             <Dropdown.Menu alignRight>
    //                                 <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+60")}>+60</Dropdown.Item>
    //                                 <Dropdown.Item onClick={() => setFrontCountryPhoneNumber("+65")}>+65</Dropdown.Item>
    //                             </Dropdown.Menu>
    //                         </Dropdown>
    //                     </Col>
    //                     <Col className="col-6">
    //                         <div className="input-group mt-2">
    //                             <input
    //                                 className="form-control"
    //                                 type="tel"
    //                                 id="numberInput"
    //                                 name="numberInput"
    //                                 placeholder={numberInput}
    //                                 onKeyPress={(e) => {
    //                                     if (!/[0-9]/.test(e.key)) {
    //                                         e.preventDefault();
    //                                     }
    //                                 }}
    //                                 disabled={phoneInputDisabled}
    //                             />
    //                         </div>
    //                     </Col>


    //                     {
    //                         showEdit
    //                             ?
    //                             <Col className="col-2">
    //                                 <Button className="mt-2" onClick={() => {
    //                                     setResendMessage(false)

    //                                     setShowEdit(false)
    //                                     setPhoneInputDisabled(false)
    //                                     setShowOtpInput(false)


    //                                 }}>Edit</Button>
    //                             </Col>
    //                             :
    //                             <Col className="col-3">
    //                                 <Button className="mt-2" onClick={() => {

    //                                     const inputValue = document.querySelector('input[name="numberInput"]').value;

    //                                     if (inputValue == "") {
    //                                         alert('Whatsapp number cannot be emtpy !')
    //                                     } else {

    //                                         if (!isRequestingOTP) {
    //                                             setIsRequestingOTP(true);
    //                                             // Proceed with OTP request
    //                                             setResendMessage(true)
    //                                             setWhatsappNumber(`${frontCountryPhoneNumber}${inputValue}`)
    //                                             setNumberInput(inputValue)
    //                                             setShowEdit(true)
    //                                             setPhoneInputDisabled(true)
    //                                             setShowOtpInput(true)
    //                                             sendOTPtoVerifyWhatsapp(`${frontCountryPhoneNumber}${inputValue}`)
    //                                             // Start cooldown timer

    //                                             setTimeout(() => {
    //                                                 setIsRequestingOTP(false);
    //                                             }, cooldownDuration * 1000);

    //                                         } else {
    //                                             alert('Oops! Please wait 30 seconds before requesting OTP again.')
    //                                         }

    //                                         // setResendMessage(true)
    //                                         // setWhatsappNumber(`${frontCountryPhoneNumber}${inputValue}`)
    //                                         // setNumberInput(inputValue)
    //                                         // setShowEdit(true)
    //                                         // setPhoneInputDisabled(true)
    //                                         // setShowOtpInput(true)
    //                                         // sendOTPtoVerifyWhatsapp(`${frontCountryPhoneNumber}${inputValue}`)
    //                                     }

    //                                 }}>Get otp</Button>
    //                             </Col>

    //                     }



    //                 </Row>
    //                 {
    //                     resendMessage &&
    //                     <Row style={{ paddingLeft: '2%' }}>
    //                         Enter your otp here
    //                     </Row>
    //                 }

    //                 {
    //                     showOtpInput &&
    //                     <Row>
    //                         <div className="input-group mt-2">
    //                             <input
    //                                 className="form-control"
    //                                 type="tel"
    //                                 id="otpInput"
    //                                 name="otpInput"
    //                                 onKeyPress={(e) => {
    //                                     if (!/[0-9]/.test(e.key)) {
    //                                         e.preventDefault();
    //                                     }
    //                                 }}
    //                             />
    //                         </div>
    //                     </Row>
    //                 }



    //             </Modal.Body>
    //             <Modal.Footer>
    //                 <Button variant="secondary" onClick={() => {
    //                     setShowVerifyWhatsapp(false)
    //                     dispatch(switchToUser())
    //                 }}
    //                 >
    //                     Cancel
    //                 </Button>
    //                 <Button variant="primary" onClick={async () => {
    //                     const otpInputValue = document.querySelector('input[name="otpInput"]').value;

    //                     // console.log(JSON.stringify({
    //                     //     "MobileNo": whatsappNumber,
    //                     //     "OTP": otpInputValue
    //                     // }))

    //                     await domainName
    //                         .serverAPICall(
    //                             domainName.debugAPI + "/user/verifyVerificationTwilioOtp",
    //                             "POST",
    //                             JSON.stringify({
    //                                 "MobileNo": whatsappNumber,
    //                                 "OTP": otpInputValue
    //                             })
    //                         )
    //                         .then(domainName.handleServerAPICallErrors)
    //                         .then((responseJson => {
    //                             // console.log('/user/verifyVerificationTwilioOtp responseJson here', responseJson)

    //                             if (responseJson.ReturnCode == 200) {
    //                                 updateWhatsappNumberWithAPI()
    //                             } else {
    //                                 alert(responseJson.ReturnDesc);
    //                             }

    //                         }))
    //                         .catch((error) => {
    //                             // console.log('/user/verifyVerificationTwilioOtp error here', error)
    //                         });


    //                 }}
    //                 //disabled={loading}
    //                 >
    //                     Confirm
    //                 </Button>
    //             </Modal.Footer>
    //         </Modal>
    //     )
    // }


    useEffect(() => {

        const loadAndChecking = async () => {
            // console.log('From nav UserData::', UserData)

            await checkProfileImage()
            // here to do check whatsapp , becuase when perform switch role will go thru here
            // console.log("advisor", advisor)
            // console.log("user", user)
            // if (user == false) {
            //     // console.log('need to check the whatsapp is being verified')
            //     await getWhatsappNumFromAPI()
            // } else {
            //     setShowVerifyWhatsapp(false)
            // }
        }

        loadAndChecking();

        const handleResize = () => {
            setIsWebsite(window.innerWidth > 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };


    }, [UserId])

    function renderSwitchRole(title) {
        return renderLabel(title, require('../img/icons/switch.png'));
    }

    function renderLabel(itemtitle, link) {
        return (<>
            <Image src={link} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />
            {itemtitle}
        </>

        )
    }

    const handleSignOut = async () => {
        // console.log('handle sign out here ! jh want to see !')
        try {
            // await logOut()
            await insertAnonymouseUser() // inside got logout function

            window.location.href = "/signin";
            // navigate("/signin");


        } catch (error) {
            console.log("handleSignOut error > ", error)
        }
    }

    //Dropdown laguage Switcher
    const [selectedValue, setSelectedValue] = useState('');

    useEffect(() => {
        const storedValue = localStorage.getItem('selectedValue');
        if (storedValue) {
            setSelectedValue(storedValue);
            i18next.changeLanguage(storedValue); // this is default
        }
    }, []);

    const handleSelectChange = (event) => {
        const value = event.target.value;
        setSelectedValue(value);
        i18next.changeLanguage(value);
        localStorage.setItem('selectedValue', value);
        window.location.reload();
    }

    return (
        <div>
            <div className="d-none d-sm-block">
                <Navbar className="orange-bar">
                    <Container className="width95">
                        <Nav className="ml-auto"> {/* Added 'ml-auto' class */}
                            {/* <Nav.Link href={isIOS ? "https://play.google.com/store/apps/details?id=com.advisor2uapp" : "https://apps.apple.com/us/app/adviser2u/id6448983215"}>Get the app</Nav.Link> */}
                            {!displayBecomeAnAdvisor && (
                                <Nav.Link href={`/BecomeAnAdvisor?uid=` + (UserData && UserData._id ? UserData._id : '')} className="white-hover">{t('navigation.Become an adviser')}</Nav.Link>
                            )}

                            {!displayBecomeAUser && (
                                <Nav.Link href={`/signup`} className="white-hover">{t('navigation.Become a user')}</Nav.Link>
                            )}

                            <Nav className="mr-auto"> {/* Added 'mr-auto' class */}
                                <Nav.Link href="/Terms" className="white-hover">{t('navigation.Terms Of Use')}</Nav.Link>
                                <Nav.Link href="/PrivacyPolicy" className="white-hover">{t('navigation.Privacy Policy')}</Nav.Link>
                                <Nav.Link href="/ContactUs" className="white-hover">{t('navigation.Contact Us')}</Nav.Link>
                                {UserData && UserData.Name && advisorState.advisor == false && <Nav.Link onClick={async () => { await switchToAdvisorScreen(); }} className="white-hover"><Image src={require('../img/icons/switch_white.png')} style={{ width: '17px' }} /> {t('navigation.Switch to adviser')}</Nav.Link>}
                                {advisorState.advisor == true && <Nav.Link onClick={async () => { await switchToUserScreen(); }} className="white-hover"><Image src={require('../img/icons/switch_white.png')} style={{ width: '17px' }} /> {t('navigation.Switch to consumer')}</Nav.Link>}
                                {/* <NavDropdown.Item href={`/myProfile?userId=${UserData._id}`}><Image src={require('../img/icons/myprofile.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />My Profile</NavDropdown.Item> */}
                            </Nav>
                        </Nav>
                        <div style={{ minWidth: '175px' }}>
                            <img src={require("../img/icons/language.png")} />
                            <select id="dropdown" value={selectedValue} onChange={handleSelectChange} className="languageSelectBox">
                                <option value="en">English</option>
                                <option value="zh">中文</option>
                                <option value="ms">Melayu</option>
                                <option value="id">Bahasa Indonesia</option>
                                <option value="tl">Tagalog</option>
                            </select>
                        </div>
                    </Container>
                </Navbar>
            </div>

            <Navbar collapseOnSelect sticky="top" className="my-nav" expand="md">
                <Container className="width95">
                    <Navbar.Brand href="/">
                        <img src={logo} width="168" height="32" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        {isWebsite && (
                            <Nav className="me-auto">
                                <Nav.Link href="/community">{t('navigation.Community')}</Nav.Link>
                                {advisorState.advisor == false && <Nav.Link href="/postRequest">{t('navigation.Post Request')}</Nav.Link>}
                                {advisorState.advisor == true && <Nav.Link href="/bookinglistAdvisor">{t('navigation.Bookings')}</Nav.Link>}
                            </Nav>
                        )}
                        {!isWebsite && (
                            <>
                                <Row>
                                    <div style={{ display: 'flex' }}>
                                        <input
                                            //type="search"
                                            placeholder={t("placeholder.Search")}
                                            //aria-label="Search"
                                            name="searchInput"
                                            style={{
                                                borderColor: COLORS.lightGray1,
                                                borderStyle: 'solid',
                                                borderWidth: 1,
                                                borderRadius: 5,
                                                padding: '1%',
                                                paddingLeft: '2%',
                                                maxWidth: '100%',
                                                height: '40px',
                                                width: '100%',
                                            }}
                                            onKeyDown={(event) => {
                                                if (event.key === 'Enter') {
                                                    event.preventDefault(); // prevent default form submission behavior
                                                    searchRef.current.click();
                                                }
                                            }}
                                        />


                                        <Button ref={searchRef} style={{ marginLeft: '2%', height: '40px', marginRight: '2%' }} onClick={() => {
                                            let searchInput = document.querySelector('input[name="searchInput"]').value;
                                            // console.log('searchInput,', searchInput)
                                            if (searchInput !== "") {
                                                window.location.href = `/ServiceListing?keyword=${searchInput}`;
                                                // navigate(`/ServiceListing?keyword=${searchInput}`, {})
                                            }
                                        }}>
                                            <img src={search} />
                                        </Button>
                                    </div>
                                </Row>
                                <Row>
                                    <div style={{ cursor: 'pointer', marginTop: '2%', flexDirection: 'row' }}>
                                        <div className="btn-white text-dark fw-bold btn"
                                            style={{
                                                backgroundColor: '#F2F2F2',
                                                textAlign: 'center',
                                                width: 'auto',
                                                maxWidth: '250px',
                                                height: '40px',
                                                marginRight: '2%',
                                            }}
                                            onClick={() => navigate('/changelocation')}>
                                            <span style={{ color: COLORS.gray, display: 'inline-block', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{locationDisplay}</span>
                                        </div>
                                        {/* <button onClick={() => navigate('/favourite')} style={{ border: 'none', background: 'none', cursor: 'pointer', padding: 0, marign: 0 }}>
                                            <img src={require('../img/icons/favouriteNav.png')} alt="Dropdown" style={{ width: '30px' }} />
                                        </button>

                                        <Nav.Link href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}&tab=notification`}>
                                            <img src={require('../img/icons/NotificationsIcon.png')} alt="Dropdown" style={{ width: '30px' }} />
                                        </Nav.Link>
                                 
                                        <Nav.Link href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}`} style={{}}>
                                            <img src={require('../img/icons/message.png')} alt="Dropdown" style={{ width: '30px' }} />
                                        </Nav.Link> */}

                                    </div>
                                </Row>
                            </>
                        )}

                        {isWebsite && advisorState.advisor == false && (
                            <div style={{ display: 'flex' }}>
                                <input
                                    //type="search" 
                                    placeholder={t("placeholder.Search")}
                                    //aria-label="Search" 
                                    name="searchInput" style={{ borderColor: COLORS.lightGray1, borderStyle: 'solid', borderWidth: 1, borderRadius: 5, padding: '1%', paddingLeft: '2%', minWidth: '90px', height: '40px' }}
                                    onKeyDown={(event) => {

                                        if (event.key === 'Enter') {
                                            event.preventDefault(); // prevent default form submission behavior
                                            searchRef.current.click();
                                        }

                                    }}
                                />

                                <div style={{ cursor: 'pointer', whiteSpace: 'nowrap', marginLeft: '2%', flex: '1' }}>
                                    <div className="btn-white text-dark fw-bold btn" style={{ backgroundColor: '#F2F2F2', textAlign: 'center', width: '100%', maxWidth: '150px', height: '40px' }} onClick={() => navigate('/changelocation')}>
                                        <span style={{ color: COLORS.gray, display: 'inline-block', maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>{locationDisplay}</span>
                                    </div>
                                </div>

                                <Button ref={searchRef} style={{ marginLeft: '2%', height: '40px', marginRight: '2%' }} onClick={() => {
                                    let searchInput = document.querySelector('input[name="searchInput"]').value;
                                    // console.log('searchInput,', searchInput)
                                    if (searchInput != "") {
                                        // window.location.reload(`/ServiceListing?keyword=${searchInput}`);
                                        // navigate(`/ServiceListing?keyword=${searchInput}`)

                                        window.location.href = `/ServiceListing?keyword=${searchInput}`;
                                        // navigate(`/ServiceListing?keyword=${searchInput}`, {})
                                    }
                                }}>
                                    <img src={search} />
                                </Button>
                            </div>
                        )}
                        {/* {user && (
                            <Nav style={{ marginLeft: '2%' }}>
                                <button onClick={() => navigate('/favourite')} style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                    <img src={require('../img/icons/favouriteNav.png')} alt="Dropdown" style={{ width: '35px' }} />
                                </button>
                            </Nav>
                        )} */}
                        <Nav>
                            {UserData && UserData.Name ? (
                                <>
                                    {isWebsite ? (
                                        <>
                                            {advisorState.advisor == false && (
                                                <Nav style={{
                                                    marginLeft: '2%'
                                                }}>
                                                    <Nav.Link href={`/favourite`} style={{ backgroundColor: COLORS.white, padding: 0, paddingTop: '8%', paddingRight: '1%' }}>
                                                        <img src={require('../img/icons/favouriteNav.png')} alt="Dropdown" style={{ width: '35px' }} />
                                                    </Nav.Link>


                                                    <Nav.Link href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}&tab=notification`}
                                                        style={{ backgroundColor: COLORS.white, padding: 0, paddingTop: '6%', paddingRight: '1%', position: 'relative' }}>

                                                        <img src={require('../img/icons/NotificationsIcon.png')} alt="Dropdown" style={{ width: '35px' }} />

                                                        {unreadNotificationCount > 0 && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '0',
                                                                    right: '0',
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                    borderRadius: '50%',
                                                                    padding: '2px 6px',
                                                                    fontSize: '10px',
                                                                }}
                                                            >
                                                                {unreadNotificationCount}
                                                            </div>
                                                        )}

                                                    </Nav.Link>


                                                    {/* <Nav.Link href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}`} style={{ backgroundColor: COLORS.white, padding: 0, paddingTop: '6%', paddingRight: '1%' }}>
                                                        <img src={require('../img/icons/message.png')} alt="Dropdown" style={{ width: '35px' }} />
                                                    </Nav.Link> */}


                                                    <Nav.Link
                                                        href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}`}
                                                        style={{ backgroundColor: COLORS.white, padding: 0, paddingTop: '6%', paddingRight: '1%', position: 'relative' }}
                                                    >
                                                        <img src={require('../img/icons/message.png')} alt="Dropdown" style={{ width: '35px' }} />
                                                        {unreadMessageCount > 0 && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '0',
                                                                    right: '0',
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                    borderRadius: '50%',
                                                                    padding: '2px 6px',
                                                                    fontSize: '10px',
                                                                }}
                                                            >
                                                                {unreadMessageCount}
                                                            </div>
                                                        )}
                                                    </Nav.Link>







                                                </Nav>
                                            )}
                                            {advisorState.advisor == true && (
                                                <Nav style={{ marginLeft: '2%' }}>
                                                    <button onClick={() => navigate('/favourite')} style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                                        <img src={require('../img/icons/favouriteNav.png')} alt="Dropdown" style={{ width: '35px' }} />
                                                    </button>


                                                    {/* <Nav.Link href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}&tab=notification`}>
                                                        <img src={require('../img/icons/NotificationsIcon.png')} alt="Dropdown" style={{ width: '30px' }} />
                                                    </Nav.Link> */}


                                                    <Nav.Link href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}&tab=notification`}
                                                        style={{ backgroundColor: COLORS.white, padding: 0, paddingTop: '6%', paddingRight: '1%', position: 'relative' }}>

                                                        <img src={require('../img/icons/NotificationsIcon.png')} alt="Dropdown" style={{ width: '35px' }} />

                                                        {unreadNotificationCount > 0 && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '0',
                                                                    right: '0',
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                    borderRadius: '50%',
                                                                    padding: '2px 6px',
                                                                    fontSize: '10px',
                                                                }}
                                                            >
                                                                {unreadNotificationCount}
                                                            </div>
                                                        )}


                                                    </Nav.Link>



                                                    {/* <button onClick={() => navigate('/message?userId=' + (UserData && UserData._id ? UserData._id : '') + '&tab=notification')} style={{ border: 'none', background: 'none', cursor: 'pointer', padding: 0, marign: 0 }}>
                                                        <img src={require('../img/icons/NotificationsIcon.png')} alt="Dropdown" style={{ width: '35px' }} />
                                                    </button> */}
                                                    <Nav.Link
                                                        href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}`}
                                                        style={{ backgroundColor: COLORS.white, padding: 0, paddingTop: '6%', paddingRight: '1%', position: 'relative' }}
                                                    >
                                                        <img src={require('../img/icons/message.png')} alt="Dropdown" style={{ width: '35px' }} />
                                                        {unreadMessageCount > 0 && (
                                                            <div
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '0',
                                                                    right: '0',
                                                                    backgroundColor: 'red',
                                                                    color: 'white',
                                                                    borderRadius: '50%',
                                                                    padding: '2px 6px',
                                                                    fontSize: '10px',
                                                                }}
                                                            >
                                                                {unreadMessageCount}
                                                            </div>
                                                        )}
                                                    </Nav.Link>


                                                    {/* <button onClick={() => navigate('/message?userId=' + (UserData && UserData._id ? UserData._id : ''))} style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer' }}>
                                                        <img src={require('../img/icons/message.png')} alt="Dropdown" style={{ width: '35px' }} />
                                                    </button> */}
                                                </Nav>
                                            )}
                                            {advisorState.advisor == false && (
                                                // <NavDropdown title={<img src={profileImage ? profileImage : 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'} alt="Dropdown" style={{ width: '35px', height: '35px', borderRadius: 20, textAlign: 'center' }} />} id="collasible-nav-dropdown" align="end">
                                                <NavDropdown
                                                    title={
                                                        <img
                                                            src={profileImage || 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'}
                                                            alt="Dropdown"
                                                            onError={(e) => {
                                                                e.target.onerror = null; // Prevent infinite loop in case fallback also fails
                                                                e.target.src = 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'; // Fallback image
                                                            }}
                                                            style={{
                                                                width: '35px',
                                                                height: '35px',
                                                                borderRadius: 20,
                                                                textAlign: 'center',
                                                            }}
                                                        />
                                                    }
                                                    id="collasible-nav-dropdown"
                                                    align="end"
                                                >

                                                    <NavDropdown.Item href="/account">
                                                        {UserData.Name}{t("navigation.'s Account")}
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Divider />
                                                    {/* <NavDropdown.Item onClick={async () => {
                                                        console.log('switch to advisor');
                                                        await switchToAdvisorScreen();
                                                    }}>
                                                        {renderSwitchRole('Switch to adviser', 'Advisor')}
                                                    </NavDropdown.Item> */}
                                                    <NavDropdown.Item href={`/myProfile?userId=${UserData._id}`}><Image src={require('../img/icons/myprofile.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.My Profile')}</NavDropdown.Item>
                                                    <NavDropdown.Item href='/mybookings'> <Image src={require('../img/icons/booking.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.My Booking')}</NavDropdown.Item>
                                                    <NavDropdown.Item href='/requestlisting'><Image src={require('../img/icons/myrequest.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.My Request')}</NavDropdown.Item>
                                                    <NavDropdown.Item href='/myReview'><Image src={require('../img/icons/myreview.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.My Review')}</NavDropdown.Item>
                                                    <NavDropdown.Item href='/accountAndSecurity'><Image src={require('../img/icons/accountSecurity.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.Account & Security')}</NavDropdown.Item>
                                                    <NavDropdown.Item href="/referReward"><Image className='ml-1' src={require('../img/icons/referRewards.png')} style={{ marginRight: '1%', width: 24, height: 24, color: COLORS.gray }} /> {t('navigation.Refer & Rewards')}</NavDropdown.Item>
                                                    <NavDropdown.Item href="/subscription"><Image src={require('../img/icons/subscription.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.Subscription')}</NavDropdown.Item>
                                                    {/* <NavDropdown.Item href="/support"><Image src={require('../img/icons/support.png')} style={{ marginRight: '1%' , width:30, height:30, color:COLORS.gray}} />Support</NavDropdown.Item> */}
                                                    <NavDropdown.Divider />
                                                    <NavDropdown.Item onClick={async () => await handleSignOut()}>{t('navigation.Logout')}</NavDropdown.Item>
                                                </NavDropdown>
                                            )}
                                            {advisorState.advisor == true && (
                                                // <NavDropdown title={<img src={profileImage ? profileImage : 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'} alt="Dropdown" style={{ width: '35px', height: '35px', borderRadius: 20 }} />} id="collasible-nav-dropdown" align="end">

                                                <NavDropdown
                                                    title={
                                                        <img
                                                            src={profileImage || 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'}
                                                            alt="Dropdown"
                                                            onError={(e) => {
                                                                e.target.onerror = null; // Prevent infinite loop in case fallback also fails
                                                                e.target.src = 'https://d2x9bdk6cd5di1.cloudfront.net/profile/no_image_1.png'; // Fallback image
                                                            }}
                                                            style={{
                                                                width: '35px',
                                                                height: '35px',
                                                                borderRadius: 20,
                                                                textAlign: 'center',
                                                            }}
                                                        />
                                                    }
                                                    id="collasible-nav-dropdown"
                                                    align="end"
                                                >

                                                    <NavDropdown.Item href="/account" >
                                                        {UserData.Name}{t("navigation.'s Account")}
                                                    </NavDropdown.Item>
                                                    <NavDropdown.Divider />
                                                    {/* <NavDropdown.Item onClick={async () => {
                                                        console.log('switch to user');
                                                        await switchToUserScreen();
                                                    }}> 
                                                        {renderSwitchRole('Switch to consumer', 'consumer')}
                                                    </NavDropdown.Item> */}
                                                    <NavDropdown.Item href={`/myProfile?userId=${UserData._id}`}><Image src={require('../img/icons/booking.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.My Profile')}</NavDropdown.Item>
                                                    <NavDropdown.Item href='/myListingsAdvisor'> <Image src={require('../img/icons/accountSecurity.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.My Listings')}</NavDropdown.Item>
                                                    <NavDropdown.Item href='/myReview'><Image src={require('../img/icons/myreview.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.My Review')}</NavDropdown.Item>
                                                    <NavDropdown.Item href='/accountAndSecurity'><Image src={require('../img/icons/accountSecurity.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.Account & Security')}</NavDropdown.Item>
                                                    <NavDropdown.Item href="/referReward"><Image className='ml-1' src={require('../img/icons/referRewards.png')} style={{ marginRight: '1%', width: 24, height: 24, color: COLORS.gray }} /> {t('navigation.Refer & Rewards')}</NavDropdown.Item>
                                                    <NavDropdown.Item href="/subscription"><Image src={require('../img/icons/subscription.png')} style={{ marginRight: '1%', width: 30, height: 30, color: COLORS.gray }} />{t('navigation.Subscription')}</NavDropdown.Item>
                                                    <NavDropdown.Divider />
                                                    <NavDropdown.Item onClick={async () => await handleSignOut()}>{t('navigation.Logout')}</NavDropdown.Item>
                                                </NavDropdown>
                                            )}
                                        </>
                                    ) : (
                                        <>

                                            <div style={{
                                                backgroundColor: '#C0281C',
                                                paddingLeft: '10px',
                                                marginTop: '5px',
                                                width: '60%',
                                                borderRadius: '5px'
                                            }}>
                                                <img src={require("../img/icons/language.png")} />
                                                <select id="dropdown" value={selectedValue} onChange={handleSelectChange} className="languageSelectBox">
                                                    <option value="en">English</option>
                                                    <option value="zh">中文</option>
                                                    <option value="ms">Melayu</option>
                                                    <option value="id">Bahasa Indonesia</option>
                                                    <option value="tl">Tagalog</option>
                                                </select>
                                            </div>



                                            <Nav.Link href={isIOS ? "https://apps.apple.com/us/app/adviser2u/id6448983215" : "https://play.google.com/store/apps/details?id=com.advisor2uapp"}>{t('navigation.Get the app')}</Nav.Link>
                                            <Nav.Link href='/favourite'>{t('navigation.Favourite')}</Nav.Link>
                                            <Nav.Link href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}&tab=notification`}>{t('navigation.Notification')}
                                                {unreadNotificationCount > 0 && (
                                                    <Badge bg='danger' className='m-1'>{unreadNotificationCount}</Badge>
                                                )}
                                            </Nav.Link>
                                            <Nav.Link href={`/message?userId=${UserData && UserData._id ? UserData._id : ''}`}>{t('navigation.Message')}
                                                {unreadMessageCount > 0 && (
                                                    <Badge bg='danger' className='m-1'>{unreadMessageCount}</Badge>
                                                )}
                                            </Nav.Link>

                                            {!displayBecomeAnAdvisor && (
                                                <Nav.Link href={`/BecomeAnAdvisor?uid=${UserData && UserData._id ? UserData._id : ''}`}>{t('navigation.Become an adviser')}</Nav.Link>
                                            )}
                                            {!displayBecomeAUser && (
                                                <Nav.Link href={`/signup`}>{t('navigation.Become a user')}</Nav.Link>
                                            )}
                                            <Nav.Link href="/Terms">{t('navigation.Terms Of Use')}</Nav.Link>
                                            <Nav.Link href="/PrivacyPolicy">{t('navigation.Privacy Policy')}</Nav.Link>
                                            <Nav.Link href="/ContactUs">{t('navigation.Contact Us')}</Nav.Link>
                                            {UserData && UserData.Name && advisorState.advisor == false && <Nav.Link onClick={async () => { await switchToAdvisorScreen(); }}>{t('navigation.Switch to adviser')}</Nav.Link>}
                                            {advisorState.advisor == true && <Nav.Link onClick={async () => { await switchToUserScreen(); }}>{t('navigation.Switch to consumer')}</Nav.Link>}
                                            <hr></hr>
                                            <Nav.Link href="/account">
                                                <span style={{ margin: 'auto' }}>{UserData.Name}</span>{t("navigation.'s Account")}
                                            </Nav.Link>
                                            {/* {advisorState.advisor == true && (<Nav.Link to="/consumer" onClick={async () => {
                                                // console.log('switch to consumer');
                                                await switchToUserScreen();
                                            }}>Switch to consumer</Nav.Link>
                                            )}
                                            {advisorState.advisor == false && (<Nav.Link to="/advisor" onClick={async () => {
                                                // console.log('switch to advisor');
                                                await switchToAdvisorScreen();
                                            }}>Switch to adviser</Nav.Link>
                                            )} */}
                                            <Nav.Link href="/myListingsAdvisor">{t('navigation.My Listings')}</Nav.Link>
                                            <Nav.Link href="/mybookings">{t('navigation.Bookings')}</Nav.Link>
                                            <Nav.Link href="/requestlisting">{t('navigation.My Request')}</Nav.Link>
                                            <Nav.Link href="/myReview">{t('navigation.My Review')}</Nav.Link>
                                            <Nav.Link href="/accountAndSecurity">{t('navigation.Account & Security')}</Nav.Link>
                                            <Nav.Link href="/referReward">{t('navigation.Refer & Rewards')}</Nav.Link>
                                            {advisorState.advisor == false && <Nav.Link href="/subscription">{t('navigation.Subscription')}</Nav.Link>}

                                            {!isWebsite && (
                                                <Nav className="me-auto">
                                                    {advisorState.advisor == false && <Nav.Link href="/postRequest">{t('navigation.Post Request')}</Nav.Link>}
                                                    {advisorState.advisor == true && <Nav.Link href="/bookinglistAdvisor">{t('navigation.Bookings')}</Nav.Link>}
                                                    <Nav.Link href="/community">{t('navigation.Community')}</Nav.Link>
                                                </Nav>
                                            )}
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <Nav.Link onClick={async () => await handleSignOut()}>{t('navigation.Logout')}</Nav.Link>
                                            </div>
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    {view == 'mobile' &&
                                        <>
                                            <div style={{
                                                backgroundColor: '#C0281C',
                                                paddingLeft: '10px',
                                                marginTop: '5px',
                                                width: '60%',
                                                borderRadius: '5px'
                                            }}>
                                                <img src={require("../img/icons/language.png")} />
                                                <select id="dropdown" value={selectedValue} onChange={handleSelectChange} className="languageSelectBox">
                                                    <option value="en">English</option>
                                                    <option value="zh">中文</option>
                                                    <option value="ms">Melayu</option>
                                                    <option value="id">Bahasa Indonesia</option>
                                                    <option value="tl">Tagalog</option>
                                                </select>
                                            </div>
                                        </>
                                    }
                                    {/* here is no login user */}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Nav.Link href="/signin">
                                            {view == 'mobile' &&
                                                <>
                                                    <Nav.Link href={isIOS ? "https://apps.apple.com/us/app/adviser2u/id6448983215" : "https://play.google.com/store/apps/details?id=com.advisor2uapp"}>{t('navigation.Get the app')}</Nav.Link>
                                                    {!displayBecomeAnAdvisor && (
                                                        <Nav.Link href={`/BecomeAnAdvisor?uid=${UserData && UserData._id ? UserData._id : ''}`}>
                                                            {t('navigation.Become an adviser')}
                                                        </Nav.Link>
                                                    )}

                                                    {!displayBecomeAUser && (
                                                        <Nav.Link href={`/signup`}>{t('navigation.Become a user')}</Nav.Link>
                                                    )}
                                                    <Nav.Link href="/Terms">{t('navigation.Terms Of Use')}</Nav.Link>
                                                    <Nav.Link href="/PrivacyPolicy">{t('navigation.Privacy Policy')}</Nav.Link>
                                                    <Nav.Link href="/ContactUs">{t('navigation.Contact Us')}</Nav.Link>
                                                    <Nav.Link href="/community">{t('navigation.Community')}</Nav.Link>
                                                    <Nav.Link href="/postRequest">{t('navigation.Post Request')}</Nav.Link>
                                                    {UserData && UserData.Name && advisorState.advisor == false && <Nav.Link onClick={async () => { await switchToAdvisorScreen(); }}>{t('navigation.Switch to adviser')}</Nav.Link>}
                                                    {advisorState.advisor == true && <Nav.Link onClick={async () => { await switchToUserScreen(); }}>{t('navigation.Switch to consumer')}</Nav.Link>}
                                                    <Nav.Link href="/signin">{t('navigation.Login')}</Nav.Link>

                                                </>
                                            }
                                            {(view == 'laptop' || view == 'monitor' || view == 'default') &&
                                                <>
                                                    <Button variant="light" style={{ whiteSpace: 'nowrap' }} >{t('navigation.Login')}</Button>
                                                </>
                                            }
                                        </Nav.Link>
                                        {(view == 'laptop' || view == 'monitor' || view == 'default') &&
                                            <>
                                                <Nav.Link onClick={() => navigate('/favourite')} style={{ border: 'none', background: 'none', padding: '0', cursor: 'pointer', marginLeft: 'auto', marginRight: '2%' }}>
                                                    <img src={require('../img/icons/favouriteNav.png')} alt="Favorite" style={{ width: '35px' }} />
                                                </Nav.Link>
                                            </>
                                        }
                                    </div>
                                </>
                            )}
                        </Nav>
                    </Navbar.Collapse>
                    {/* <VerifyWhatsappModal /> */}
                </Container>
            </Navbar>


        </div >

    );
}

export default Navigation;